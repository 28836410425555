import Vue from 'vue'
import VueIframe from 'vue-iframes'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import VueSimpleAlert from "vue-simple-alert";
import i18n from './i18n'
const VueApexCharts = () => import("vue-apexcharts")

Vue.config.productionTip = false
Vue.use(VueSimpleAlert);
Vue.use(VueIframe);
Vue.use(require('vue-moment'));
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
